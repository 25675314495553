import * as React from 'react';
import * as styles from './filter-overlay.module.css';

const Filter = ({ name, active, onSelected }) => {
    const classNames = [styles.filter];
    if (active) {
        classNames.push(styles.active);
    }

    return (
        <div className={classNames.join(' ')} onClick={(e) => {
            e.stopPropagation();
            onSelected(name);
        }}>
            {name}
        </div>
    )
}

export default Filter;