import * as React from 'react';
import { Link } from 'gatsby';
import * as styles from './grid.module.css';

import Thumbnail from '../thumbnail/thumbnail';

const Grid = ({ mdxNodes }) => {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.grid}>
                {mdxNodes.map(node => (
                    <div id={node.slug.slice(0, -1)} className={styles.gridCell} key={node.id} >
                        <Link to={`/projects/${node.slug}`}>
                            <Thumbnail 
                                title={node.frontmatter.title}
                                subtitle={node.frontmatter.type}
                                videoSrc={node.frontmatter.thumbnailVideo?.videoH264?.path}
                                imageSrc={node.frontmatter.thumbnailImage}
                            />
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default Grid;