import * as React from 'react';
import Filter from './filter';
import * as styles from './filter-overlay.module.css';

const FilterOverlay = ({ 
    allFilters, 
    activeFilters, 
    onCloseClicked, 
    onFilterToggled, 
    onFiltersCleared 
}) => {
    return (
        <div className={styles.filterOverlayContainer + " heading"} onClick={onCloseClicked}>
            {allFilters.map(type => (
                <div className={styles.filterContainer}>
                    <Filter 
                        key={type}
                        name={type}
                        active={type in activeFilters}
                        onSelected={(name) => onFilterToggled(name)}
                    />
                </div>
            ))}
            <a className={styles.clearBtn} onClick={(e) => {
                e.stopPropagation();
                onFiltersCleared();
            }}>Clear Filters</a>
            <a className={styles.closeBtn} onClick={onCloseClicked}>×</a>
        </div>
    )
};

export default FilterOverlay;