import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import Grid from '../components/grid/grid';
import FilterOverlay from '../components/filter/filter-overlay';
import { Helmet } from "react-helmet"

import * as styles from '../styles/index.module.css';

const IndexPage = ({ data }) => {
  const [filterOverlayVisible, setFilterOverlayVisible] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState({}); 

  const showFilterOverlay = () => setFilterOverlayVisible(true);
  const hideFilterOverlay = () => setFilterOverlayVisible(false);
  const toggleFilter = (name) => {
    const activeFiltersCopy = { ...activeFilters };

    if (name in activeFiltersCopy) {
      delete activeFiltersCopy[name];
    } else {
      activeFiltersCopy[name] = true;
    }
    
    setActiveFilters(activeFiltersCopy);
  };
  const clearFilters = () => setActiveFilters({});

  const filtersExist = Object.keys(activeFilters).length > 0;

  const filterButton = (
    <div className={styles.filterBtnContainer}>
      {filtersExist && <div className={styles.filterActiveIcon}></div>}
      <a onClick={showFilterOverlay}>Work</a>
    </div>
  );

  const filteredNodes = filtersExist > 0 ? data.allMdx.nodes.filter(node => {
    for (const activeFilter in activeFilters) {
      if (node.frontmatter.skillTags.includes(activeFilter)) {
        return true;
      }
    }
    return false;
  }) : data.allMdx.nodes;

  return (
    <Layout
      title="Sanketh Hegde"
      navLeft={filterButton}
    >
      <Helmet title="Sanketh Hegde" />
      <Grid mdxNodes={filteredNodes} />
      {filterOverlayVisible && 
        <FilterOverlay 
          allFilters={data.allMdx.distinctTypes}
          activeFilters={activeFilters}
          onCloseClicked={hideFilterOverlay}
          onFilterToggled={toggleFilter}
          onFiltersCleared={clearFilters}
        />
      }
    </Layout>
  )
};

export const query = graphql`
  query {
    allMdx(
      sort: {fields: [frontmatter___priority, frontmatter___title]}
      filter: {frontmatter: {hidden: {ne: true}}}
    ){
      distinctTypes: distinct(field: frontmatter___skillTags)
      nodes {
        frontmatter {
          title
          type
          skillTags
          thumbnailVideo {
            videoH264(fps: 10, crf: 34, maxHeight: 600) {
              path
            }
          }
          thumbnailImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug
      }
    }
  }
`;

export default IndexPage;