import * as React from 'react';
import * as styles from './thumbnail.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Thumbnail = ({ title, subtitle, videoSrc, imageSrc }) => {
    return (
        <div className={styles.thumbnail}>
            <div className={styles.thumbnailOverlayContainer}>
                <div className={styles.thumbnailOverlay}>
                    <div className={styles.thumbnailTitle + " heading"}>
                        {title}
                    </div>
                    <div className={styles.thumbnailSubtitle + " paragraph"}>
                        {subtitle}
                    </div>
                </div>
            </div>
            {
                videoSrc ?
                <video className={styles.thumbnailMedia} muted playsInline autoPlay loop>
                    <source src={videoSrc} type="video/mp4" />
                </video> :
                <GatsbyImage className={styles.thumbnailMedia} image={getImage(imageSrc)} alt="" />
            }
        </div>
    )
};

export default Thumbnail;